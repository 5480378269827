import * as React from 'react';
import './Style.scss';
import { PaymentPlanInfo } from 'Interfaces/PaymentPlanInterface';
import { Button, Card, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import { navigateAddModal, navigateRemoveModal } from 'actions/navigationAction';
import { cancelAutoRenew } from 'actions/paymentActions';
import { ConfirmationModalProps } from 'views/Modals/ConfirmationModal';
import { useAppDispatch } from 'index';
import { JSONInterface } from 'Interfaces/JsonInterface';
import { ModalComponentNames } from 'Interfaces/ModalInterface';
import { getAccountUserStats } from 'actions/adminUserActions';
import TransactionBundle from '../form/TransactionBundle';
import { getTransactionCost } from '../utils';
import { getLocalization } from '../../../global/global';
import DetailsForm from '../DetailsForm';

interface Props {
  paymentPlanInfo: PaymentPlanInfo;
  openPaymentsTab: () => void;
  updateSubscription: (newValues: JSONInterface) => void;
  initPayments: () => void;
  setAdditionalUsers: (additionalUsers: JSONInterface) => Promise<boolean>;
}

export default function Plans(props: Props) {
  const { paymentPlanInfo } = props;

  const dispatch = useAppDispatch();
  const [data, setData] = React.useState<JSONInterface>({
    oneTimePayment: 0,
    totalCost: 0
  });

  const [validate, setValidate] = React.useState(false);
  const [noOfUsersInvalid, setNoUsersInvalid] = React.useState(false);
  const [userStats, setUserStats] = React.useState<JSONInterface | 'loading' | undefined>(undefined);

  const [moreTransactions, setMoreTransactions] = React.useState(false);
  const [renewOrMoreUsers, setRenewOrMoreUsers] = React.useState<'RENEW' | 'MORE_USERS' | ''>('');

  const [hasFuturePlan, setHasFuturePlan] = React.useState(false);

  const onDataChange = (name: string, value: string | number) => {
    setData({...data, [name]: value});
  };

  React.useEffect(() => {
    if (!userStats && userStats !== 'loading') {
      setUserStats('loading');
      getAccountUserStats().then(json => {
        setUserStats(json);
      }).catch(e => {
        console.log(e);
      });
    }
  }, [userStats]);


  React.useEffect(() => {
    const { futureLicenseValidTo } = paymentPlanInfo;
    setHasFuturePlan(futureLicenseValidTo && '0000-00-00' !== futureLicenseValidTo ? true : false);
  }, [paymentPlanInfo]);

  const objOnDataChange = (obj) => {
    setData({...data, ...obj});
  };

  React.useEffect(() => {
    props.updateSubscription(data);
    const prepNumberOfUsers = Number(data.prepNumberOfUsers);
    console.log(prepNumberOfUsers);
    if (renewOrMoreUsers === 'RENEW' && userStats && prepNumberOfUsers > 0) {
      if ((
        paymentPlanInfo.pricingMethod === 'Per Transaction' && prepNumberOfUsers < userStats['noOfOfficeUsers']
      ) || (paymentPlanInfo.pricingMethod === 'Per User' && prepNumberOfUsers < userStats['activeUsers'])) {
        setNoUsersInvalid(true);
        return;
      }
    }
    setNoUsersInvalid(false);
  }, [data]);

  React.useEffect(() => {
    const txCost = Number(getTransactionCost(data['transactions']));
    const users = Number(data.prepNumberOfUsers || 0);
    let perUserCost = 0;
    let remainingDays = 0;
    if (renewOrMoreUsers === 'RENEW') {
      perUserCost = Number(paymentPlanInfo[`chargePerUser${paymentPlanInfo.plan}`]) * 10;
    } else {
      const perDay = Number(paymentPlanInfo[`chargePerUser${paymentPlanInfo.plan}`]) / 30;
      remainingDays = moment(paymentPlanInfo.validTo).diff(moment(), 'days');
      perUserCost = perDay * remainingDays;
    }
    const userCost = users * perUserCost;
    setData({
      ...data,
      subscriptionMonths: (remainingDays / 30).toFixed(2),
      totalCost: (Math.round(
        (Number(data['oneTimePayment']) + Number(data['transcription'] || 0) + txCost + userCost) * 100
      ) / 100).toFixed(2)
    });
  }, [data.oneTimePayment, data.transactions, data.prepNumberOfUsers, data.transcription]);

  const openPaymentsTab = () => {
    /// const { prepNumberOfUsers, pricingMethod, transactions } = data;
    if (!Number(data['totalCost'])) {
      setValidate(true);
    } else {
      props.openPaymentsTab();
    }
  };

  /* const setAdditionalUsers = async () => {
    if (!data['prepNumberOfUsers'] || data['prepNumberOfUsers'] === 0) {
      setValidate(true);
    }
    const requestToastId = Date.now() + Math.floor(Math.random() * 100);
    toast(getLocalization('processingPayment'), {
      toastId: requestToastId,
      type: toast.TYPE.INFO,
      autoClose: false,
      closeButton: false,
      hideProgressBar: true,
      closeOnClick: false
    });
    const response = await props.setAdditionalUsers({
      additionalUsers: data['prepNumberOfUsers']
    });
    if (response) {
      toast.update(requestToastId, {
        type: toast.TYPE.SUCCESS,
        render: getLocalization('additionalUsersAdded')
      });
    } else {
      toast.update(requestToastId, {
        type: toast.TYPE.ERROR,
        render: getLocalization('additionalUsersError')
      });
    }
    setTimeout(() => toast.dismiss(requestToastId), 3000);
  };*/

  const confirmCancelAutoRenew = () => {
    const confirmModal: ConfirmationModalProps = {
      onConfirm: () => {
        dispatch(cancelAutoRenew());
        dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
      },
      onCancel: () => {
        dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
      },
      onClose: () => {
        dispatch(navigateRemoveModal(ModalComponentNames.ConfirmationModal));
      },
      localizations: {
        cancel: getLocalization('no'),
        confirm: getLocalization('yes'),
        confirmStyle: 'danger',
        header: <>{getLocalization('confirm')}</>,
        body: (<>{getLocalization('confirmCancelAutoRenew')}</>)
      }
    };
    dispatch(navigateAddModal({component: ModalComponentNames.ConfirmationModal, props: confirmModal}));
  };

  const FuturePlans = () => {
    const { futureMaxUsers } = paymentPlanInfo;
    return (
      <>
        {hasFuturePlan && (
          <tr>
            <td>{getLocalization('futurePlanValidTo')}</td>
            <td>{moment(paymentPlanInfo.futureLicenseValidTo).format('L')}</td>
          </tr>
        )}
        {futureMaxUsers && Number(futureMaxUsers) > 0 && (
          <tr>
            <td>{getLocalization('futurePlanMaxUsers')}</td>
            <td>{futureMaxUsers}</td>
          </tr>
        )}
        {paymentPlanInfo.futurePackage && (
          <tr>
            <td>{getLocalization('futurePlanPackage')}</td>
            <td>{paymentPlanInfo.futurePackage}</td>
          </tr>
        )}
      </>
    );
  };

  const AutoRenew = () => {
    return (
      <tr>
        <td>{getLocalization('autoRenew')}</td>
        <td>
          {getLocalization(paymentPlanInfo.autoRenew === 'true' ? 'yes' : 'no')}
          {paymentPlanInfo.autoRenew === 'true' ? (
            <Button
              size="sm"
              variant="danger"
              className="cancel-auto-renew"
              onClick={confirmCancelAutoRenew}
            >
              {getLocalization('cancelAutoRenew')}
            </Button>
          ) : null}
        </td>
      </tr>
    );
  };

  return (
    <Row>
      <Col>
        <Card>
          <Card.Header>
            {getLocalization(paymentPlanInfo.payingCustomer === 'no' ? 'annualLicense' : 'currentPlan')}
          </Card.Header>
          <Card.Body>
            {paymentPlanInfo.payingCustomer === 'no' && (<p>{getLocalization('trialAlert')}</p>)}
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td>{getLocalization('plan')}</td>
                  <td>{paymentPlanInfo.plan}</td>
                </tr>
                <tr>
                  <td>{getLocalization('pricingMethod')}</td>
                  <td>{paymentPlanInfo.pricingMethod}</td>
                </tr>
                {/* <tr>
                  <td>{getLocalization('paymentSchedule')}</td>
                  <td>{paymentPlanInfo.paymentSchedule}</td>
                </tr>
                <tr>
                  <td>{getLocalization('monthSubscribed')}</td>
                  <td>{paymentPlanInfo.monthsSubscribed}</td>
                </tr>*/}
                <tr>
                  <td>{getLocalization('licenseValidFrom')}</td>
                  <td>{moment(paymentPlanInfo.validFrom).format('L')}</td>
                </tr>
                <tr>
                  <td>{getLocalization('licenseValidTo')}</td>
                  <td>{moment(paymentPlanInfo.validTo).format('L')}</td>
                </tr>
                <tr>
                  <td>{getLocalization('monthlyCostPerUser')}</td>
                  <td>${paymentPlanInfo[`chargePerUser${paymentPlanInfo.plan}`]}</td>
                </tr>
                <tr>
                  <td>{getLocalization('annualCostPerUser')}</td>
                  <td>${Number(paymentPlanInfo[`chargePerUser${paymentPlanInfo.plan}`]) * 10}</td>
                </tr>
                <tr>
                  <td>{getLocalization('currentActiveUsers')}</td>
                  <td>{paymentPlanInfo.activeUsers}</td>
                </tr>
                <tr>
                  <td>{getLocalization('paidAnnualLicenses')}</td>
                  <td>{Number(paymentPlanInfo.maxUsers) + Number(paymentPlanInfo.officeUsers)}</td>
                </tr>
                {paymentPlanInfo.maxTransactions && (
                  <tr>
                    <td>{getLocalization('paidTransactions')}</td>
                    <td>{Number(paymentPlanInfo.maxTransactions)}</td>
                  </tr>
                )}
                <tr>
                  <td>{getLocalization('transcriptions')}</td>
                  <td>
                    ${Number(paymentPlanInfo.transcriptionBudget || 0)}
                    /${Number(paymentPlanInfo.transcriptionUsage || 0)}
                  </td>
                </tr>
                <FuturePlans />
                <AutoRenew />
              </tbody>
            </Table>
            {paymentPlanInfo.payingCustomer === 'no' ? (
              <DetailsForm
                paymentPlanInfo={paymentPlanInfo}
                updateSubscription={props.updateSubscription}
                openPaymentsTab={props.openPaymentsTab}
              />
            ) : (
              <Form className="payment-form">
                <Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>{
                      getLocalization('oneTimePayments')
                    }</Form.Label>
                    <Form.Control
                      type="number"
                      size="sm"
                      value={data['oneTimePayment']}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onDataChange('oneTimePayment', e.target.value)
                      }
                    />
                    <Form.Text className="text-muted">
                      {getLocalization('oneTimePaymentsAdvice')}
                    </Form.Text>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>{
                      getLocalization('purchaseTranscription')
                    }</Form.Label>
                    <Form.Control
                      type="text"
                      size="sm"
                      value={data['transcription']}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!e.target.value.match(new RegExp(/[^0-9]/))) {
                          onDataChange('transcription', e.target.value);
                        } else {
                          onDataChange('transcription', data.transcription);
                        }
                      }}
                    />
                    <Form.Text className="text-muted">
                      {getLocalization('costPerMinute')}
                    </Form.Text>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={6}>
                    <Col>
                      <div className="renew-add-users-div">
                        {paymentPlanInfo.pricingMethod === 'Per Transaction' && (
                          <Button
                            size="sm"
                            onClick={() => {
                              setMoreTransactions(true);
                              setRenewOrMoreUsers('');
                              objOnDataChange({ 'prepNumberOfUsers' : 0, 'validTo': null, 'transactions': null });
                            }}
                          >
                            {getLocalization('moreTransactions')}
                          </Button>
                        )}
                        {paymentPlanInfo.autoRenew !== 'true' ? (
                          <Button
                            size="sm"
                            disabled={hasFuturePlan}
                            title={paymentPlanInfo.futureLicenseValidTo ? getLocalization('futurePlanAvailable') : ''}
                            onClick={() => {
                              setRenewOrMoreUsers('RENEW');
                              setMoreTransactions(false);
                              objOnDataChange({
                                'prepNumberOfUsers' : paymentPlanInfo.activeUsers,
                                'pricingMethod': paymentPlanInfo.pricingMethod,
                                'validTo': moment(paymentPlanInfo.validTo)
                                  .add(12, 'months').format('YYYY-MM-DD'), 'transactions': null
                              });
                            }}
                          >
                            {getLocalization('renew')}
                          </Button>
                        ) : null}
                        <Button
                          size="sm"
                          onClick={() => {
                            setMoreTransactions(false);
                            setRenewOrMoreUsers('MORE_USERS');
                            objOnDataChange({ 'prepNumberOfUsers' : 0, 'validTo': null, 'transactions': null });
                          }}
                        >
                          {getLocalization('addUsers')}
                        </Button>
                      </div>
                    </Col>
                    {moreTransactions && (
                      <TransactionBundle
                        value={data['transactions']}
                        onChange={(v) => onDataChange('transactions', `${v}`)}
                      />
                    )}
                    {renewOrMoreUsers && (
                      <>
                        <Form.Group as={Col}>
                          <Form.Label>
                            {
                              getLocalization(renewOrMoreUsers === 'RENEW' ?
                                paymentPlanInfo.pricingMethod === 'Per User' ? 'prepNumberOfUsers' :
                                  'estimatedNoOfficeUsers' : 'additionalUsers')
                            }
                            {renewOrMoreUsers === 'RENEW' && (
                              <> &nbsp;&nbsp;
                                ({moment(props.paymentPlanInfo.validTo).format('YYYY/MM/DD')}
                                  - {moment(data.validTo).format('YYYY/MM/DD')})
                              </>
                            )}
                          </Form.Label>
                          {renewOrMoreUsers === 'RENEW' ? (
                            <>
                              <Form.Control
                                type="text"
                                size="sm"
                                value={data['prepNumberOfUsers']}
                                isInvalid={noOfUsersInvalid}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (!e.target.value.match(new RegExp(/[^0-9]/))) {
                                    onDataChange('prepNumberOfUsers', e.target.value);
                                  } else {
                                    onDataChange('prepNumberOfUsers', data.prepNumberOfUsers);
                                  }
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {getLocalization('activeUsersAlert').replace(
                                  /{{current}}/g,
                                  !userStats ? '' : paymentPlanInfo.pricingMethod === 'Per User' ?
                                    `${userStats['activeUsers']}` : `${userStats['noOfOfficeUsers']}`
                                )}
                              </Form.Control.Feedback>
                            </>
                          ) : (
                            <>
                              <Form.Control
                                type="text"
                                size="sm"
                                value={data['prepNumberOfUsers']}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (!e.target.value.match(new RegExp(/[^0-9]/))) {
                                    onDataChange('prepNumberOfUsers', e.target.value);
                                  } else {
                                    onDataChange('prepNumberOfUsers', data.prepNumberOfUsers);
                                  }
                                }}
                              />
                              <Form.Text>{getLocalization('newLicenseProrate')}</Form.Text>
                            </>
                          )}
                        </Form.Group>
                        {/* renewOrMoreUsers === 'MORE_USERS' && (
                          <Button
                            size="sm"
                            onClick={() =>  void setAdditionalUsers()}
                          >
                            {getLocalization('updateUsers')}
                          </Button>
                        )*/}
                      </>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>{getLocalization('totalCost')}</Form.Label>
                    <InputGroup className="mb-3" size="sm">
                      <InputGroup.Prepend>
                        <InputGroup.Text>$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        readOnly
                        value={data['totalCost']}
                        isInvalid={!data['totalCost'] && validate}
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
                {renewOrMoreUsers === 'RENEW' && (
                  <Row>
                    <Form.Group as={Col} md={6}>
                      <Form.Check
                        id={'renew-automatically'}
                        label={getLocalization('autoRenew')}
                        checked={data['autoRenew']}
                        onChange={
                          (e: React.ChangeEvent<HTMLInputElement>) => onDataChange('autoRenew', e.target.checked)
                        }
                      />
                    </Form.Group>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Button size="sm" onClick={openPaymentsTab}>
                      {getLocalization('proceedToPayment')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Card.Body>
        </Card>
      </Col>
      {/* <Col>
        <Card>
          <Card.Header>{getLocalization('nextPaidPlan')}</Card.Header>
          <Card.Body>
            <Table striped={true} bordered={true} hover={true}>
              <tbody>
                <tr>
                  <td>{getLocalization('plan')}</td>
                  <td>{paymentPlanInfo.futurePackage}</td>
                </tr>
                <tr>
                  <td>{getLocalization('pricingMethod')}</td>
                  <td>{paymentPlanInfo.futurePricingPlan}</td>
                </tr>
                <tr>
                  <td>{getLocalization('paymentSchedule')}</td>
                  <td>{paymentPlanInfo.futurePaymentSchedule}</td>
                </tr>
                <tr>
                  <td>{getLocalization('monthSubscribed')}</td>
                  <td>{paymentPlanInfo.futureSubscriptionMonths}</td>
                </tr>
                <tr>
                  <td>{getLocalization('licenseValidFrom')}</td>
                  <td>{paymentPlanInfo.futureLicenseValidFrom}</td>
                </tr>
                <tr>
                  <td>{getLocalization('licenseValidTo')}</td>
                  <td>{paymentPlanInfo.futureLicenseValidTo}</td>
                </tr>
                <tr>
                  <td>{getLocalization('active')}</td>
                  <td>{paymentPlanInfo.active}</td>
                </tr>
              </tbody>
            </Table>
            <Button variant="primary">
              {getLocalization('updateNextPlan')}
            </Button>
          </Card.Body>
        </Card>
      </Col>*/}
    </Row>
  );
}
