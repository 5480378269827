import * as React from 'react';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { AnyAction } from 'redux';

import { ThunkDispatch } from 'redux-thunk';
import { LoginRequestInterface } from 'Interfaces/LoginInterface';
import { ClientPersistInterface } from 'Interfaces/ClientPersistInterface';
import { getLocalization, globalWindow } from 'global/global';
import { doLogin } from 'actions/userActions';
import AlertContainer from 'views/Alert/AlertContainer';
import { FormInterface } from 'Interfaces/Forms/FormsInterface';
import { Container, Jumbotron, Navbar } from 'react-bootstrap';
import { DataPoint } from 'Interfaces/DataPoint';
import { savePOI } from 'actions/pois';
import ProgressContainer from '../Progress/ProgressContainer';
import { StateInterface } from '../../Interfaces/StateInterface';
import AddData from './components/AddData';

interface ActionProps {
  login: (data: LoginRequestInterface) => Promise<void>;
  savePOI: (dataPoint: DataPoint) => Promise<Response>;
}

interface StateProps {
  clientPersist: ClientPersistInterface;
  forms: FormInterface[];
  form?: FormInterface;
}

export const Campaign = (props: ActionProps & StateProps) => {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [loggingIn, setLoggingIn] = React.useState(false);
  const [showComplete, setShowComplete] = React.useState(false);

  React.useEffect(() => {
    if (!loggedIn && !loggingIn) {
      setLoggingIn(true);
      const hash = globalWindow.location.hash;
      if (hash !== '') {
        const hashParts = hash.substring(1, hash.length).split('/');
        void props.login({
          userName: hashParts[2],
          password: '',
          instance: hashParts[0],
          activation_id: 'online-survey',
          campaignId: hashParts[3],
          campaignUserId: hashParts[4]
        });
      }
    }
  });

  React.useEffect(() => {
    const { userName, instance } = props.clientPersist;
    if (userName && instance && loggingIn) {
      setLoggedIn(true);
      setLoggingIn(false);
    }
  }, [props.clientPersist]);

  const saveQuestionnaire = (dataPoint: DataPoint) => {
    const savePoiPromise = props.savePOI(dataPoint);
    savePoiPromise.then(response => response.json()).then(json => {
      console.log(json);
      setShowComplete(true);
    }).catch(e => {
      console.log(e);
    });
  };

  return (
    <div>
      <ProgressContainer />
      <AlertContainer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
      <Navbar
        variant={'dark'}
        bg={'dark'}
        collapseOnSelect
        expand="lg"
        className={'HeaderContainer'}
      >
        <Navbar.Brand>
          <img src={'/res/images/logo.png'} className="logo" alt="Poimapper" width="129" height="19" />
        </Navbar.Brand>
      </Navbar>
      {props.form && !showComplete && (
        <Container fluid>
          <h3>
            <img src="/icons/large/poi_1.png" />
            {props.form.name}
          </h3>
          <AddData
            form={props.form}
            clientPersist={props.clientPersist}
            forms={props.forms}
            saveQuestionnaire={saveQuestionnaire}
          />
        </Container>
      )}
      {showComplete && (
        <Jumbotron className="text-center">
          <h1>{getLocalization('poimapperSurvey')}</h1>
          <p>{getLocalization('thankYou')}</p>
        </Jumbotron>
      )}
    </div>
  );
};

const mapStateToProps = (state: StateInterface): StateProps => {
  const hash = globalWindow.location.hash;
  let form: FormInterface | undefined;
  if (hash !== '' && state.forms.loaded) {
    const hashParts = hash.substring(1, hash.length).split('/');
    form = hashParts[1] ? state.forms.collection.find(f => f.id === hashParts[1]) : undefined;
  }
  return {
    clientPersist: state.clientPersist,
    form: form,
    forms: state.forms.collection
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StateInterface, any, AnyAction>): ActionProps => {
  return {
    login: (data: LoginRequestInterface) => {
      return dispatch(doLogin(data, false));
    },
    savePOI: (dataPoint: DataPoint) => dispatch(savePOI(dataPoint))
  };
};


export const CampaignApp = withCookies(
  connect(mapStateToProps, mapDispatchToProps)(Campaign));
export default CampaignApp;
