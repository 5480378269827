import * as React from 'react';
import bind from 'bind-decorator';
import { Col, Form, Row } from 'react-bootstrap';
import '../styles/IntQuestion.scss';
import { QuestionInterface, questionTypes } from 'Interfaces/Forms/QuestionInterface';
import FormUtils from '../utils/FormUtils';
import { DataPoint } from '../../../Interfaces/DataPoint';
import { LooseObject } from '../../../Interfaces/LooseObject';
import { validateNumericQuestion } from '../utils/Validator';
import { checkValidationRuleError } from '../utils/utils';
import { Elements } from '.';
import QuestionLabel from './QuestionLabel';
import TextInputComponent from './TextInputComponent';

interface State {
  question: LooseObject;
  value: null | number | string;
  edit: boolean;
  hasError: boolean;
  extraAttrs: LooseObject;
  errorText?: string;
}

interface Props {
  question: QuestionInterface;
  dataPoint: DataPoint;
  edit: boolean;
  updateAnswer: (value: LooseObject) => void;
  formUtils: FormUtils;
  isSubQuestion?: boolean;
}

export default class IntQuestion extends React.Component <Props, State> {
  private input = React.createRef<TextInputComponent>();
  constructor(props) {
    super(props);
    const { dataPoint, question } = this.props;
    const extraAttrs = { step: '1', min: undefined, max: undefined, readOnly: !props.edit };
    if (question.type === questionTypes.FLOAT_QUESTION) {
      extraAttrs.step = '.1';
    } else {
      if (question.triggerValues && question.triggerValues.triggerValue) {
        for (const triggerValue of question.triggerValues.triggerValue) {
          if (triggerValue.operatorType === 'LESS_THAN') {
            extraAttrs.min = triggerValue.value;
            // if (triggerValue.action && triggerValue.action.notification) {
            // minNotification = triggerValue.action.notification.text;
            // }
          } else if (triggerValue.operatorType === 'GREATER_THAN') {
            extraAttrs.max = triggerValue.value;
            // if (triggerValue.action && triggerValue.action.notification) {
            // maxNotification = triggerValue.action.notification.text;
            // }
          }
        }
      }
    }
    if (question.id === 'uid') {
      extraAttrs.readOnly = true;
    }
    this.state = {
      extraAttrs: extraAttrs,
      value: dataPoint[question.id] ? dataPoint[question.id] : question.default ? question.default : '',
      question: question,
      edit: props.edit,
      hasError: false,
    };
    this.input = React.createRef();
  }

  @bind
  private handleChange(value: any) {
    if (this.input.current) {
      if (this.input.current.checkValidity()) {
        const { question } = this.state;
        const { updateAnswer } = this.props;
        const hasError = false;
        if (`${value}`.indexOf('.') !== -1 && question.type === 'IntQuestion') {
          value = `${value}`.substring(0, `${value}`.indexOf('.'));
        }
        this.setState({ value, hasError });
        if (updateAnswer) {
          const newAns = {};
          newAns[question.id] = value === '' ? null : Number(value);
          updateAnswer(newAns);
        }
      } else {
        this.setState({ hasError : true, value });
      }
    }
  }

  public static getDerivedStateFromProps(props: Props, state: State) {
    const { question, dataPoint, formUtils } = props;
    const { value } = state;
    if (dataPoint['validate']) {
      if ((!question.optional && value === '') ||
      validateNumericQuestion(question, dataPoint[question.id])) {
        return { hasError: true };
      }
      let hasError = false;
      let errorText: string | undefined;
      if (dataPoint['invalidValidationRules']) {
        const ivr = dataPoint['invalidValidationRules'];
        const errors = checkValidationRuleError(question.id, ivr, formUtils);
        hasError = errors.hasError;
        errorText = errors.errorText;
      }
      return { hasError, errorText };
    }
    return null;
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value || this.state.edit !== nextState.edit
      || nextProps.dataPoint.validate !== this.props.dataPoint.validate
      || this.state.hasError !== nextState.hasError;
  }

  public render(): JSX.Element {
    const { question, extraAttrs, hasError, errorText } = this.state;
    const { formUtils, dataPoint, isSubQuestion } = this.props;
    const horizontal = question.showValueInParallel &&
      !formUtils.getModel().responsiveLayout &&
      !`${question.text}`.endsWith('---') ? true : false;
    const required = question.optional ? null : (<span className="text-danger">{` * `}</span>);
    const hasErrorClass = (required && dataPoint.validate && this.state.value === '') || hasError ? 'is-invalid' : '';
    const className =
      ` numeric-question ${hasErrorClass}` +
      `${!horizontal ? formUtils.getResponsiveView(question, isSubQuestion) : 'container-fluid'}`;
    const control = (
      <>
        <TextInputComponent
          type="number"
          name={question.id}
          extraAttrs={extraAttrs}
          value={this.state.value || ''}
          disabled={!this.state.edit}
          ref={this.input}
          extraClass={hasErrorClass}
          onChange={this.handleChange}
        />
        {hasError && errorText && (
          <div className="invalid-feedback">{errorText}</div>
        )}
      </>
    );
    return (
      <Form.Group
        as={horizontal ? Row : undefined}
        className={className}
      >
        <QuestionLabel
          question={question}
          dataPoint={dataPoint}
          formUtils={formUtils}
          horizontal={horizontal}
        >
          {required}
        </QuestionLabel>
        {horizontal ? (
          <Col sm={10}>
            {control}
          </Col>
        ) : control}
        {formUtils.getModel().childUI && (
          <Elements.FileQuestion
            accept={question.accept}
            question={question}
            dataPoint={dataPoint}
            updateAnswer={this.props.updateAnswer}
            edit={false}
            formUtils={formUtils}
          />
        )}
      </Form.Group>
    );
  }
}
