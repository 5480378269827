import { Locations } from 'Interfaces/LocationInterface';
import sma from 'sma';
import { linearRegression, linearRegressionLine } from 'simple-statistics';
import { isNullOrUndefined, pad, sortObjects } from 'utils/utils';
import { getLocalization } from 'global/global';
import { QueryFiltersInterface } from 'reducers/filtersMenuReducer';
import { ChartData, ChartDataPoints, ChartModel, ChartResponse, SeriesData } from '../../Interfaces/ChartInterface';
import { JSONInterface } from '../../Interfaces/JsonInterface';
import { FormInterface } from '../../Interfaces/Forms/FormsInterface';
import { getFormUtils } from '../../views/SingleInstance/utils/FormUtilsHolder';

export const compare = (a, b) => {
  if (a.x < b.x) {
    return -1;
  } else if (a.x > b.x) {
    return 1;
  } else {
    return 0;
  }
};

export const formatLineData = (data) => {
  const times: string[] = [];
  for (const d of data) {
    const lineData = JSON.parse(d.value);
    for (const ld of lineData) {
      if (times.indexOf(ld.x) === -1) {
        times.push(ld.x);
      }
    }
  }
  times.sort();
  const newValues = times.map(() => null);
  const newData = [...data];
  for (const d of newData) {
    const lineData = JSON.parse(d.value);
    const newValue = [...newValues];
    for (const ld of lineData) {
      const index = times.indexOf(ld.x);
      if (index !== -1) {
        newValue[index] = ld;
      }
    }
    d.value = JSON.stringify(newValue);
  }
  return newData;
};

const getUniq = (array) => {
  const temp: string[] = [];
  array.forEach((arr) => {
    if (temp.indexOf(arr) === -1) {
      temp.push(arr);
    }
  });
  return temp;
};

export const getAverage = (value, count) => {
  return Math.round((value / Number(count)) * 100) / 100;
};

export const getBarChart = (
  model: ChartModel,
  chartResponse: ChartResponse,
  forms: FormInterface[],
  xAxisLabels?,
  locations?: Locations,
  chartDataPoints?: ChartDataPoints,
  sort?: boolean,
  sortDirection?: string
) => {
  const tempCategories = chartResponse.series.map((chartData) => {
    return chartData.x;
  });
  let categories: string[] = getUniq(tempCategories);
  const tempStacks = chartResponse.series.map((chartData) => chartData.stack).filter((stack) => stack !== null);
  const stacks = getUniq(tempStacks);

  const seriesData: any = [];
  chartResponse.series.forEach((series) => {
    if (stacks.length === 0) {
      if (seriesData.length === 0) {
        seriesData[0] = {
          name: model.xaxis || model.type === 'BAR_CHOICE' ? chartResponse.chartTitle : series.x,
          data: [],
          color: series.color,
          formId: series.formId || chartResponse.formId
        };
      }
      const dataIndex = categories.indexOf(series.x);
      seriesData[0].data[dataIndex] = series.color ? { y: series.y, color: series.color } : series.y;
    } else if (series.stack) {
      const seriesIndex = stacks.indexOf(series.stack);
      if (!seriesData[seriesIndex]) {
        seriesData[seriesIndex] = {
          name: series.stack,
          data: [],
          color: series.color,
          label: chartResponse.chartTitle,
          formId: series.formId || chartResponse.formId
        };
      }
      const dataIndex = categories.indexOf(series.x);
      seriesData[seriesIndex].data[dataIndex] = series.color ? { y: series.y, color: series.color } : series.y;
    }
  });

  categories.forEach((category, index) => {
    for (const ser of seriesData) {
      if (!ser.data[index]) {
        ser.data[index] = 0;
      }
    }
  });

  let title = chartResponse.chartTitle;
  if (model.grouping) {
    const location = locations ? locations.find((l) => `${l.key}` === `${chartResponse.grouping}`) : '';
    if (location) {
      title = `${title} - ${location.title}`;
      seriesData[0].grouping = location.key;
    }
  } else if (model.type === 'BAR_CHOICE') {
    const form = forms.find(f => f.ref === chartResponse.formId);
    title = `${form?.name} - ${chartResponse.chartTitle}`;
  }
  const charts: any = [];

  const sortBars = (ser, c, direction) => {
    const dt = ser.map((sd, index) => {
      return { series: sd, category: c[index] };
    });
    const sorted = direction === 'sortAsc' ? dt.sort((a, b) => {
      return sortObjects(a, b, 'series');
    }) : dt.sort((a, b) => {
      return sortObjects(b, a, 'series');
    });
    const sortedSD = sorted.map((sd) => sd.series);
    const newCategories = sorted.map((sd) => sd.category);
    return { sortedSD, newCategories };
  };
  if (sort) {
    const { sortedSD, newCategories } = sortBars(seriesData[0].data, categories, sortDirection);
    categories = newCategories;
    seriesData[0].data = sortedSD;
  }

  const chartObject = getBarChartObject(
    model,
    seriesData,
    categories,
    title,
    '',
    forms,
    `Data points: ${chartResponse.count}`,
    xAxisLabels,
    chartDataPoints
  );
  chartObject['id'] = `${model.id}-0-${sort ? 'sorted' : 'unsorted'}`;
  chartObject['responseId'] = chartResponse.id;
  charts.push(chartObject);
  return charts;
};

/**
 * Combines the fields into one chart
 * @param chartResponse chart response from the server
 */
export const combineCharts = (chartResponses: ChartResponse[], forms: FormInterface[]) => {
  const categories: string[] = [];
  const seriesData: any = [];
  const stacks: string[] = [];
  const chars = 'abcdefghijklmnopqrstuvwxyz';
  /* const getStackName = (chartResponse: ChartResponse) => {
    return chartResponse.chartTitle?.replace(/ /g, '-').toLocaleLowerCase();
  };*/
  chartResponses.forEach((chartResponse, index) => {
    /**
     * Get x coordinate values.
     */
    const tempCategories = chartResponse.series.map((chartData) => {
      return chartData.x;
    });
    const cleaned = getUniq(tempCategories);
    cleaned.forEach((category) => {
      if (categories.indexOf(category) === -1) {
        categories.push(category);
      }
    });

    /**
     * Get the stack information.
     */
    const tempStacks = chartResponse.series.map((chartData) => chartData.stack).filter((stack) => stack !== null);
    if (tempStacks.length > 0) {
      const cleanedStack = getUniq(tempStacks);
      cleanedStack.forEach((stack) => {
        if (stacks.indexOf(stack) === -1) {
          stacks.push(stack);
        }
      });
    }
    const form = forms.find(f => f.ref === chartResponse.formId);
    // const stackName = getStackName(chartResponse);
    chartResponse.series.forEach((series) => {
      if (series.stack) {
        const dataIndex = categories.indexOf(series.x); // stackName
        const sdIndex = seriesData.findIndex(
          (sd1) => sd1.name === series.stack && sd1.stack === chars.charAt(index),
        );
        if (sdIndex === -1) {
          const sdData: number[] = [];
          sdData[dataIndex] = series.y;
          seriesData.push({
            name: series.stack,
            data: sdData,
            stack: chars.charAt(index), // stackName,
            label: `${form ? `${form.name} - ` : ''}${chartResponse.chartTitle}`,
            stackLabel: chars.charAt(index),
            formId: chartResponse.formId,
            color: series.color,
          });
        } else {
          seriesData[sdIndex].data[dataIndex] = series.y;
        }
      } else {
        if (!seriesData[index]) {
          seriesData[index] = {
            label: `${chartResponse.chartTitle}`,
            data: [],
            formId: chartResponse.formId,
            name: chartResponse.chartTitle,
          };
        }
        const dataIndex = categories.indexOf(series.x);
        seriesData[index].data[dataIndex] = series.y;
      }
    });
  });
  seriesData.forEach((sd) => {
    for (let i = 0; i < sd.data.length; i++) {
      if (!sd.data[i]) {
        sd.data[i] = 0;
      }
    }
  });
  console.log(categories);
  console.log(seriesData);
  return { categories, seriesData: seriesData.filter(sd => !isNullOrUndefined(sd)) };
};

/**
 * For Select questions to be stacked with no x-axis selected.
 * @param chartResponse chart response from the server
 */
export const stackOptions = (chartResponse: ChartResponse, model: ChartModel) => {
  const seriesData = chartResponse.series.map((response) => {
    return {
      name: response.x,
      data: [model.average ? getAverage(response.y, chartResponse.count) : response.y],
    };
  });
  const xAxis = chartResponse.xAxisTitle || chartResponse.chartTitle || '';
  const categories = [xAxis];
  return { categories, seriesData };
};

export const getBarChartObject = (
  chartModel: ChartModel,
  seriesData: any,
  categories: string[],
  title: string | null,
  xAxisTitle: string | null,
  forms: FormInterface[],
  subtitle?: string,
  xAxisLabels?: JSONInterface,
  chartDataPoints?: ChartDataPoints,
) => {
  const getCaption = () => {
    const labels = {};
    seriesData.forEach((sd) => {
      if (sd['label'] && sd['stack'] && !labels[sd['stack']]) {
        labels[sd['stack']] = `${sd['stack']} - ${sd['label']}<br/>`;
      }
    });
    const keys = Object.keys(labels);
    const html = keys.map((key) => labels[key]).join('');
    return html;
  };
  console.log(JSON.stringify(categories));
  console.log(JSON.stringify(seriesData));

  return {
    chart: {
      type: chartModel.verticalBars === '1' ? 'column' : 'bar',
      // height: '70%',
      events: {
        load: function() {
          console.log('loading');
        },
        render: function () {
          const labels = {};

          seriesData.forEach((sd) => {
            if (sd['stack'] && !labels[sd['stack']]) {
              labels[sd['stack']] = `${sd['stack']} - ${sd['label']}<br/>`;
            }
          });
          const keys = Object.keys(labels);
          const html = keys.map((key) => labels[key]);
          console.log(html);
          // chart.target.renderer.text(html, 15, chart.target.chartHeight).add();
          console.log('render chart');
        },
        afterPrint: function() {
          // this.xAxis[0].options.max = categories.length > 3 ? 4 : undefined;
          const chart = this; // eslint-disable-line @typescript-eslint/no-this-alias
          if (categories.length > 3) {
            chart.xAxis[0].setExtremes(0, 4);
            // chart.xAxis[0].options.max = undefined;
            // chart.render();
            chart.update({
              xAxis: {
                max: 4,
              }
            }, true);
            this.render();
          }
        },
      },
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subtitle,
    },
    yAxis: {
      min: 0,
      // max: 2000,
      minTickInterval: 1,
      allowDecimals: chartModel.type !== 'BAR_CHOICE',
      title: {
        text: null,
      },
      labels: {
        overflow: 'justify',
      },
      stackLabels: {
        enabled: true,
        formatter: function () {
          const $this = this; // eslint-disable-line @typescript-eslint/no-this-alias
          console.log(this);
          // @ts-ignore // series.userOptions.stackLabel
          return $this.stack;
        },
      },
    },
    xAxis: {
      categories: categories,
      type: 'category',
      title: {
        text: xAxisTitle,
      },
      min: 0,
      max: categories.length > 3 ? 4 : undefined,
      minTickInterval: 1,
      scrollbar: {
        enabled: true
      },
      labels: {
        formatter: function () {
          const $this = this; // eslint-disable-line @typescript-eslint/no-this-alias
          // @ts-ignore
          return xAxisLabels ? xAxisLabels[$this.value] : $this.value;
        },
      },
    },
    plotOptions: {
      series: {
        stacking: chartModel.stack ? 'normal' : null,
        dataLabels: {
          enabled: true,
        },
      },
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      enabled:
                chartModel.xaxis &&
                chartModel.combine !== 'LOCATION' &&
                (chartModel.combine === 'FIELD' ||
                    chartModel.stack === 'FIELD' ||
                    chartModel.stack === 'OPTIONS' ||
                    chartModel.type === 'BAR_CHOICE'),
      labelFormatter: function () {
        const $this = this; // eslint-disable-line @typescript-eslint/no-this-alias
        // console.log($this);
        // @ts-ignore
        return `${$this.name}`;
      }
    },
    series: seriesData,
    tooltip: {
      stickOnContact: true,
      useHTML: true,
      // @ts-ignore
      formatter: function () {
        const formId = this.series.userOptions.formId;
        const yAxis = chartModel.xaxis || chartModel.type === 'BAR_CHOICE' ?
          this.series.userOptions.label || this.series.name : this.x;
        const chartData = chartDataPoints ? chartDataPoints[this.series.userOptions.formId] : null;
        const dataPointHtml = chartData ? chartData[yAxis] ?
          `<button class='btn btn-primary btn-sm' onClick="showChartModal(
            '${chartModel.id}',
            '${this.x}',
            '${chartModel.xaxis ? chartModel.xaxis : ''}',
            '${yAxis}',
            '${chartModel.xaxis || chartModel.type === 'BAR_CHOICE' ? this.series.userOptions.name : ''}',
            '${chartModel.grouping ? `location${chartModel.grouping}` : ''}',
            '${this.series.userOptions.grouping}',
            '${this.series.userOptions.formId}'
          )">View datapoints</button>` : `` : '';
        const form = forms.find(f => f.ref === formId)?.name;
        // when no x axis is selected & no combination and bar choice then show series name (question text)
        const getHeader = () => (!chartModel.xaxis && !chartModel.combine && chartModel.type === 'BAR_CHOICE' ?
          this.series.name : (xAxisLabels ? xAxisLabels[this.x] : this.x)) ;
        // @ts-ignore
        return (
          '<b>' +
                    (form && chartModel.showFormName ? `Form : ${form}` : '') + '<br/>' +
                    getHeader() +
                    '</b><br/>' +
                    // @ts-ignore
                    (this.series.userOptions.label || '') +
                    '<br/>' +
                    // @ts-ignore
                    (chartModel.xaxis || (!chartModel.xaxis && chartModel.combine) ? this.series.name : this.x) +
                    ': ' +
                    this.y +
                    '<br/>' +
                    // @ts-ignore
                    (this.point.stackTotal ? 'Total: ' + this.point.stackTotal : '') +
                    '</b><br/>' +
                    `<ol> ${dataPointHtml}</ol>`
        );
      },
    },
    caption: {
      text: getCaption(),
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen", {
            text: 'Print',
            onclick: function() {
              const chart = this; // eslint-disable-line @typescript-eslint/no-this-alias
              chart.xAxis[0].setExtremes(null, null);
              // chart.xAxis[0].options.max = undefined;
              // chart.render();
              chart.update({
                xAxis: {
                  max: undefined,
                }
              }, true);
              setTimeout(function() {
                chart.print();
              }, 600);
            },
            separator: false
          }, "separator", {
            text: 'Download PNG image',
            onclick: function() {
              const chart = this; // eslint-disable-line @typescript-eslint/no-this-alias
              chart.xAxis[0].setExtremes(null, null);
              setTimeout(function() {
                chart.exportChartLocal({ type: 'image/png' });
              }, 600);
            }
          }, {
            text: 'Download JPEG image',
            onclick: function() {
              const chart = this; // eslint-disable-line @typescript-eslint/no-this-alias
              chart.xAxis[0].setExtremes(null, null);
              setTimeout(function() {
                chart.exportChartLocal({ type: 'image/jpeg' });
              }, 600);
            }
          }, {
            text: 'Download PDF document',
            onclick: function() {
              const chart = this; // eslint-disable-line @typescript-eslint/no-this-alias
              chart.xAxis[0].setExtremes(null, null);
              setTimeout(function() {
                chart.exportChartLocal({ type: 'application/pdf' });
              }, 600);
            }
          }, {
            text: 'Download SVG vector image',
            onclick: function() {
              const chart = this; // eslint-disable-line @typescript-eslint/no-this-alias
              chart.xAxis[0].setExtremes(null, null);
              setTimeout(function() {
                chart.exportChartLocal({ type: 'image/svg+xml' });
              }, 600);
            }
          }]
        }
      },
      chartOptions: { // specific options for the exported image
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        xAxis: [{
          // type: 'category',
          min: undefined,
          max: undefined,
          labels: {
            style: {
              fontSize: '0.4em'
            }
          },
          scrollbar: {
            enabled: false
          }
        }]
      },
      scale: 3,
      fallbackToExportServer: false
    },
  };
};

/**
 * For bar chart for choices, the options become the x axis.
 */
export const switchChart = (chartResponse: ChartResponse) => {
  const newData = chartResponse.series.map((series) => {
    return {
      x: series.stack || '',
      y: series.y,
      stack: series.x,
    };
  });
  return { ...chartResponse, series: newData };
};

export const switchNumericCharts = (
  chartResponse: ChartResponse[], model: ChartModel, locations?: Locations, forms?: FormInterface[]
) => {
  interface Series {
    name: string;
    data: number[];
  }
  const switchUp = (categories, seriesData) => {
    const newCategories: string[] = seriesData.map((sd) => sd.name);
    const newSeries: SeriesData[] = [];
    categories.forEach((category, index) => {
      const series: Series = {
        name: category,
        data: [],
      };
      seriesData.forEach((sd) => {
        series.data.push(sd.data[index]);
      });
      newSeries.push(series);
    });
    return {
      categories: newCategories,
      seriesData: newSeries,
      title: '',
    };
  };
  if (model.combine === 'FIELD') {
    const combined = combineCharts(chartResponse, forms);
    return switchUp(combined.categories, combined.seriesData);
  } else if (model.combine === 'LOCATION') {
    const chartGroups: { [key: string]: ChartResponse[] } = {};
    if (model.questions.length > 1) {
      chartResponse.forEach((c) => {
        if (c.chartTitle) {
          chartGroups[c.chartTitle] = chartGroups[c.chartTitle] || [];
          chartGroups[c.chartTitle].push(c);
        }
      });
    } else {
      chartGroups[chartResponse[0].chartTitle || 'Chart 1'] = chartResponse;
    }
    const titleKeys = Object.keys(chartGroups);
    const response: any = [];
    titleKeys.forEach((chartGroup) => {
      const { categories, seriesData } = getCombineLocation(chartGroups[chartGroup], locations);
      const newSwicthed = switchUp(categories, seriesData);
      newSwicthed.title = chartGroup;
      response.push(newSwicthed);
    });
    return response;
  }
};

export const cleanSeriesData = (seriesData) => {
  seriesData.forEach((sd) => {
    for (let i = 0; i < sd.data.length; i++) {
      if (!sd.data[i]) {
        sd.data[i] = 0;
      }
    }
  });
  return seriesData;
};

export const getCombinedCharts = (
  combined,
  model: ChartModel,
  xAxisLabels,
  forms: FormInterface[],
  location?: string,
  chartDataPoints?: ChartDataPoints
) => {
  const title = location ? 'Combined charts ' + (location ? ` - ${location}` : '') : model.name;
  const charts: any = [];
  if (combined.categories.length > 11) {
    // const length = combined.categories.length;
    const max = 10;
    // let count = 0;
    while (combined.categories.length > 0) {
      const newCat = combined.categories.splice(0, max);
      const seriesTemp = JSON.parse(JSON.stringify(combined.seriesData));
      // const index = count * max;
      combined.seriesData.forEach((series, index) => {
        seriesTemp[index].data = series.data.splice(0, max);
      });
      const chartObject = getBarChartObject(
        model,
        seriesTemp,
        newCat,
        title,
        '',
        forms,
        '',
        xAxisLabels,
        chartDataPoints
      );
      chartObject['id'] = `${model.id}`;
      charts.push(chartObject);
      // count++;
    }
  } else {
    const chartObject = getBarChartObject(
      model,
      combined.seriesData,
      combined.categories,
      title,
      '',
      forms,
      '',
      xAxisLabels,
      chartDataPoints
    );
    chartObject['id'] = model.id;
    charts.push(chartObject);
  }
  return charts;
};

export const groupChartResponsesByLocation = (data: ChartResponse[]) => {
  const groupedResponses: { [key: string]: ChartResponse[] } = {};
  data.forEach((cr) => {
    if (cr.grouping) {
      groupedResponses[cr.grouping] = groupedResponses[cr.grouping] || [];
      groupedResponses[cr.grouping].push(cr);
    }
  });
  return groupedResponses;
};

export const getBarChartNoXAxis = (
  data: ChartResponse[],
  model: ChartModel,
  forms: FormInterface[],
  locations?: Locations,
  chartDataPoints?: ChartDataPoints
) => {
  const charts: any = [];
  const seriesData: SeriesData[] = [];
  const categories: string[] = [];
  data.forEach((d) => {
    const l = locations?.find((l) => `${l.key}` === `${d.grouping}`);
    if (l) {
      categories.push(l.title);
      d.series.forEach((s, index) => {
        const sd: SeriesData = seriesData[index] || {
          name: s.x,
          data: [],
        };
        sd.data.push(s.y);
        if (!seriesData[index]) {
          seriesData[index] = sd;
        }
      });
    }
  });
  if (model.combine === 'LOCATION' && !model.stack && model.questions.length > 1) {
    seriesData.forEach((sd, index) => {
      const readyChart = getBarChartObject(
        model, [sd], categories, sd.name, '', forms, undefined, undefined, chartDataPoints
      );
      readyChart['id'] = data[index]['id'];
      charts.push(readyChart);
    });
  } else {
    const readyChart = getBarChartObject(
      model, seriesData, categories,
      'Combined charts',
      '',
      forms,
      undefined,
      undefined,
      chartDataPoints
    );
    readyChart['id'] = data[0]['id'];
    charts.push(readyChart);
  }
  return charts;
};

/**
 * When combine field is selected and there is an x-axis defined,
 * this function organizes the data and returned the chart objects
 * @param data - ChartResponse list from the server
 * @param model - ChartModel with parameters
 * @param xAxisLabels - X-Axis labels
 * @param locations - List of Locations
 */
export const getCombinedFieldCharts = (
  data: ChartResponse[],
  model: ChartModel,
  forms: FormInterface[],
  xAxisLabels?: JSONInterface,
  locations?: Locations,
  chartDataPoints?: ChartDataPoints
) => {
  const charts: any = [];
  if (model.grouping) {
    const groupedResponses: { [key: string]: ChartResponse[] } = groupChartResponsesByLocation(data);
    const keys = Object.keys(groupedResponses);
    keys.forEach((key) => {
      const combined = combineCharts(groupedResponses[key], forms);
      const location = locations?.find((l) => `${l.key}` === key);
      getCombinedCharts(
        combined, model, xAxisLabels, forms, location ? location.title : undefined, chartDataPoints
      ).forEach((chart) =>
        charts.push(chart),
      );
    });
  } else {
    const combined = combineCharts(data, forms);
    /* if (combined.seriesData.length > 10) {
      const max = 10;
      while (combined.seriesData.length > 0) {
        const newSeries = combined.seriesData.splice(0, max);
        getCombinedCharts(
          {
            categories: combined.categories,
            seriesData: newSeries,
          },
          model,
          xAxisLabels,
        ).forEach((chart) => charts.push(chart));
      }
    } else {
    } */
    getCombinedCharts(combined, model, xAxisLabels, forms, undefined, chartDataPoints)
      .forEach((chart) => charts.push(chart));
  }
  return charts;
};

export const getBarCharts = (
  model: ChartModel,
  data: ChartResponse[],
  form: FormInterface,
  forms: FormInterface[],
  locations?: Locations,
  chartDataPoints: ChartDataPoints,
) => {
  const customX = () => {
    return !model.xaxis && ((model.stack === 'FIELD' && model.grouping) || model.combine === 'LOCATION');
  };
  let charts: any = [];
  let xAxisLabels: JSONInterface | undefined;
  /* if (model.formId && model.formId.indexOf('-') !== -1) {
    const formUtil = getFormUtils(form);
    const id = model.formId.split('-');
    const question = formUtil.getQuestion(id[1]);
    if (question) {
      const table = question.table;
      if (table) {
        const columns = table.columns;
        if (columns && columns.column) {
          const xAxisLabel = {};
          columns.column[0].question.forEach((qn) => {
            if (!qn.inVisible && !qn.deleted) {
              xAxisLabel[qn.id] = qn.text;
            }
          });
          xAxisLabels = xAxisLabel;
        }
      }
    }
  } else */
  if (form && form.type === 'TABLE' && form.static && model.type === 'BAR_NUMERIC') {
    const mainFormId = form.source;
    if (mainFormId) {
      const mainForm = forms.find((f) => f.ref === mainFormId);
      if (mainForm) {
        const formUtil = getFormUtils(mainForm);
        if (form.questionId) {
          const rows = formUtil.getRows(form.questionId);
          const xAxisLabel = {};
          rows.forEach((row) => {
            if (row.id) {
              xAxisLabel[row.id] = row.value || row.id;
            }
          });
          xAxisLabels = xAxisLabel;
        }
      }
    }
  }
  // if (model.grouping && !)
  if (model.combine === 'FIELD') {
    if (!model.xaxis && model.stack === 'OPTIONS') {
      const categories: string[] = [];
      const seriesData: any = [];
      data.forEach((response, index) => {
        const chartData = stackOptions(response, model);
        categories.push(chartData.categories[0]);
        chartData.seriesData.forEach((sd) => {
          const ind = seriesData.findIndex((sd1) => sd1.name === sd.name);
          if (ind === -1) {
            const tempData: number[] = [];
            tempData[index] = model.average ? getAverage(sd.data[0], response.count) : sd.data[0];
            seriesData.push({
              name: sd.name,
              data: tempData,
              formId: response.formId
            });
          } else {
            const sd1 = seriesData[ind];
            sd1.data[index] = model.average ? getAverage(sd.data[0], response.count) : sd.data[0];
          }
        });
      });
      const chartObject = getBarChartObject(
        model,
        cleanSeriesData(seriesData),
        categories,
        '',
        '',
        forms,
        undefined,
        undefined,
        chartDataPoints
      );
      chartObject['id'] = model.id;
      // chartObject['responseId'] = chartResponse.id;
      charts.push(chartObject);
    } else {
      charts = charts.concat(getCombinedFieldCharts(data, model, forms, xAxisLabels, locations, chartDataPoints));
    }
  } else if (customX()) {
    charts = charts.concat(getBarChartNoXAxis(data, model, forms, locations, chartDataPoints));
  } else if (model.combine === 'LOCATION') {
    const chartGroups: { [key: string]: ChartResponse[] } = {};
    if (model.questions.length > 1) {
      fillMissingDateValues(data).forEach((c) => {
        if (c.chartTitle) {
          chartGroups[c.chartTitle] = chartGroups[c.chartTitle] || [];
          chartGroups[c.chartTitle].push(c);
        }
      });
    } else {
      chartGroups[data[0].chartTitle || 'Chart 1'] = data;
    }
    const titleKeys = Object.keys(chartGroups);
    const stackedSeries: any[] = [];
    const mainCategories: string[] = [];
    titleKeys.forEach((chartGroup) => {
      const { categories, seriesData } = getCombineLocation(chartGroups[chartGroup], locations);
      if (!model.stack) {
        getCombinedCharts(
          { categories, seriesData }, model, xAxisLabels, forms, chartGroup, chartDataPoints
        ).forEach((chart) => {
          charts.push(chart);
        });
      } else {
        if (mainCategories.length === 0) {
          categories.forEach((c) => mainCategories.push(c));
        }
        seriesData.forEach((sd) => {
          sd.stack = sd.name;
          sd.name = chartGroup;
          stackedSeries.push(sd);
        });
      }
    });
    if (stackedSeries.length > 0) {
      getCombinedCharts(
        {
          categories: mainCategories,
          seriesData: cleanSeriesData(stackedSeries),
        },
        model,
        xAxisLabels,
        forms,
        undefined,
        chartDataPoints
      ).forEach((chart) => {
        charts.push(chart);
      });
    }
  } else {
    // When there is no x-axis and we have more than one form selected, combine the charts into one.
    if (!model.xaxis && data.length > 1 && model.type === 'BAR_NUMERIC') {
      const newData: ChartResponse = {...data[0]};
      const newSeries: ChartData[] = [];
      data.forEach((chartResponse) => {
        chartResponse.series.forEach(series => {
          newSeries.push({...series, formId: chartResponse.formId});
        });
      });
      newData.series = newSeries;
      const readyChart = getBarChart(model, newData, forms, xAxisLabels, locations, chartDataPoints);
      charts = charts.concat(readyChart);
    } else {
      data.forEach((chartResponse) => {
        let readyChart;
        switch (model.type) {
          case 'BAR_CHOICE':
          case 'BAR_NUMERIC':
            if (model.stack === 'OPTIONS' && !model.xaxis) {
              const chart = stackOptions(chartResponse, model);
              readyChart = getBarChartObject(
                model,
                chart.seriesData,
                chart.categories,
                chartResponse.chartTitle,
                '',
                forms,
                undefined,
                undefined,
                chartDataPoints,
              );
              readyChart['id'] = chartResponse['id'];
              charts.push(readyChart);
            } else {
              readyChart = getBarChart(model, chartResponse, forms, xAxisLabels, locations, chartDataPoints);
              charts = charts.concat(readyChart);
            }
            break;
          default:
            break;
        }
      });
    }
  }
  return charts;
};

export const fillMissingDateValues = (chartResponse: ChartResponse[]) => {
  const xAxisValues: string[] = [];
  chartResponse.forEach((xAxis) => {
    xAxis.series.forEach((element) => {
      if (xAxisValues.indexOf(element.x) === -1) {
        xAxisValues.push(element.x);
      }
    });
  });
  chartResponse.forEach((xAxis) => {
    const tempXAxis = [...xAxisValues];
    xAxis.series.forEach((el) => {
      const index = tempXAxis.indexOf(el.x);
      tempXAxis.splice(index, 1);
    });
    tempXAxis.forEach((el) => {
      xAxis.series.push({
        x: el,
        y: 0,
      });
    });
    xAxis.series.sort(compare);
  });
  return chartResponse;
};

export const getCombineLocation = (chartResponse: ChartResponse[], locations?: Locations) => {
  const groupedResponses: { [key: string]: ChartResponse[] } = groupChartResponsesByLocation(chartResponse);
  const keys = Object.keys(groupedResponses);
  const categories: string[] = [];
  const seriesData: SeriesData[] = [];
  // index would be the data index in the series.
  keys.forEach((key, index) => {
    const location = locations?.find((l) => `${l.key}` === key);
    if (location) {
      categories.push(location.title);
      groupedResponses[key].forEach((gr) => {
        // sIndex would be the index in series data
        gr.series.forEach((series, sIndex) => {
          seriesData[sIndex] = seriesData[sIndex] || { name: series.x, data: [] };
          seriesData[sIndex].data[index] = series.y;
        });
      });
    }
  });
  return { categories, seriesData };
};

export const getPieChartObject = (chartModel: ChartModel, chartResponse: ChartResponse, locations?: Locations) => {
  const data = chartResponse.series.map((series) => {
    return {
      name: series.x,
      y: chartModel.average ? getAverage(series.y, chartResponse.count) : series.y,
      color: series.color
    };
  });
  let title = chartResponse.chartTitle;
  if (chartResponse.grouping) {
    const location = locations?.find((l) => `${l.key}` === `${chartResponse.grouping}`);
    if (location) {
      title = `${title} - ${location.title}`;
    }
  }
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: title,
    },
    subtitle: {
      text: `Data points: ${chartResponse.count}`,
    },
    tooltip: {
      pointFormat: 'value: {point.y}, <b>{point.percentage:.1f}%</b>',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: '',
        colorByPoint: true,
        data: data,
      },
    ],
  };
};

export const getSeriesCategories = (model: ChartModel, chartResponse: ChartResponse, cummulative?: boolean) => {
  const stacks = getUniq(chartResponse.series.map((series) => series.stack).filter((s) => s));
  const categories: any[] = [];
  const seriesData: any[] = [];
  const seriesAverage = {
    name: `${getLocalization('average')} ${chartResponse.chartTitle}`,
    data: [],
    color: undefined
  };
  chartResponse.series.forEach((series, i) => {
    const index = stacks.length === 0 || !series.stack ? 0 : stacks.indexOf(series.stack);
    if (!seriesData[index]) {
      seriesData[index] = {
        name: !series.stack ? chartResponse.chartTitle : series.stack,
        data: [],
        color: series.color,
        label: chartResponse.chartTitle,
        formId: series.formId || chartResponse.formId
      };
    }
    const v = series.y; // model.average ? getAverage(series.y, chartResponse.count) :
    const avg = model.averageLine ? getAverage(series.y, series.count) : 0;
    if (model.scale === 'WEEK') {
      categories[Number(i) + 1] = series.x;
      seriesData[index].data.push([Number(i) + 1, v]);
      seriesAverage.data.push([Number(i) + 1, avg]);
    } else if (model.scale === 'YEAR') {
      seriesData[index].data.push([Date.parse(`${Number(series.x)}`), v]);
      seriesAverage.data.push([Date.parse(`${Number(series.x)}`), avg]);
    } else {
      seriesData[index].data.push([Date.parse(series.x), v]);
      seriesAverage.data.push([Date.parse(series.x), avg]);
    }
  });
  console.log(seriesData);
  if (cummulative) {
    for (const series of seriesData) {
      series.data.forEach((value, index) => {
        if (index > 0) {
          value[1] = series.data[index - 1][1] + series.data[index][1];
        }
      });
    }
  }
  /* if (seriesAverage.data.length > 0) {
    seriesData.push(seriesAverage);
  }*/
  return { categories, seriesData };
};

export const getLineChart = (
  model: ChartModel,
  chartResponse: ChartResponse,
  forms: FormInterface[],
  cummulative?: boolean,
  locations?: Locations,
  chartDataPoints?: ChartDataPoints
) => {
  let title = '';
  if (model.grouping) {
    const l = locations?.find((l) => `${l.key}` === `${chartResponse.grouping}`);
    if (l) {
      title = l.title;
    }
  }

  const { categories, seriesData } = getSeriesCategories(model, chartResponse, cummulative);
  seriesData[0].data.sort((a, b) => {
    return sortCompare(a[0], b[0]);
  });
  if (seriesData[1]) {
    seriesData[1].data.sort((a, b) => {
      return sortCompare(a[0], b[0]);
    });
  }

  interface LineChartData { name: string; data: number[][]; color: string}
  if (model.movingAverage && model.averageInput !== undefined && model.averageInput > 0) {
    const smaArray = sma(seriesData[0].data.map(d => d[1]), model.averageInput);
    let start = Math.ceil(model.averageInput / 2);
    if (model.averageInput % 2 === 0) {
      start  = start + 1;
    }
    const smaData: LineChartData = {
      name: 'Moving average',
      data: [],
      color: 'black'
    };
    seriesData[0].data.forEach((data, index) => {
      if (index >= start) {
        if (smaArray[index - start]) {
          smaData.data.push([data[0], Number(smaArray[index - start])]);
        }
      }
    });
    seriesData.push(smaData);
  }
  if (model.linearAverage) {
    const MINUTES = 60000;
    const newData = seriesData[0].data.map(d => [d[0] / MINUTES, d[1]]);
    const mb = linearRegression(newData);
    const line = linearRegressionLine(mb);

    const linearData: LineChartData = {
      name: 'Linear regression',
      data: [],
      color: 'green'
    };
    seriesData[0].data.forEach(d => {
      linearData.data.push([d[0], line(d[0] / MINUTES)]);
    });
    seriesData.push(linearData);
  }

  if (model.targetLine) {
    const form = forms.find(f => f.ref === chartResponse.formId);
    if (form) {
      const fu = getFormUtils(form);
      const q = fu?.getQuestion(chartResponse.questionId);
      if (q && q.targetValue) {
        const targetLine = seriesData[0].data.map((d) => [d[0], q.targetValue]);
        seriesData.push({
          name: getLocalization('targetLine'),
          data: targetLine,
          color: 'red'
        });
      }
    }
  }
  return getLineChartObject(
    `${title}`,
    chartResponse.count,
    chartResponse.id,
    seriesData,
    `${chartResponse.chartTitle}`,
    `${chartResponse.xAxisTitle}`,
    categories,
    `${model.scale}`,
    model,
    forms,
    chartDataPoints,
    `${chartResponse.grouping ? chartResponse.grouping : ''}`
  );
};

/**
 * This function returns charts with the fields combined into one chart.
 * If there is any grouping to be applied, the fields are combined per group.
 *
 * @param model Chart model with the parameters
 * @param chartResponse Chart response from the server
 * @param cummulative Whether we generate a cummulative chart
 * @param locations List of locations
 */
export const getCombinedLineChart = (
  model: ChartModel,
  chartResponse: ChartResponse[],
  forms: FormInterface[],
  cummulative?: boolean,
  locations?: Locations,
  chartDataPoints?: ChartDataPoints
) => {
  const charts: any[] = [];
  if (model.grouping) {
    const chartGroup: { [key: string]: ChartResponse[] } = {};
    chartResponse.forEach((response) => {
      if (response.grouping) {
        chartGroup[response.grouping] = chartGroup[response.grouping] || [];
        chartGroup[response.grouping].push(response);
      }
    });

    const keys = Object.keys(chartGroup);
    keys.forEach((key) => {
      const l = locations?.find((l) => `${l.key}` === `${key}`);
      if (l) {
        const { mainSeries, mainCategories } = getMainSeriesCategories(model, chartGroup[key], cummulative);
        charts.push(
          getLineChartObject(
            `Combined ${l.title}`,
            chartResponse[0].count,
            chartResponse[0].id,
            mainSeries,
            ``,
            ``,
            mainCategories[0],
            `${model.scale}`,
            model,
            forms,
            chartDataPoints,
            key
          ),
        );
      }
    });
  } else {
    const { mainSeries, mainCategories } = getMainSeriesCategories(model, chartResponse, cummulative);
    const count = chartResponse.reduce((c, v) => c + v.count, 0);
    charts.push(
      getLineChartObject(
        model.name,
        count,
        chartResponse[0].id,
        mainSeries,
        '',
        ``,
        mainCategories[0],
        `${model.scale}`,
        model,
        forms,
        chartDataPoints
      ),
    );
  }
  return charts;
};

export const getMainSeriesCategories = (model: ChartModel, r: ChartResponse[], cummulative?: boolean) => {
  const mainCategories: any[] = [];
  const mainSeries: any[] = [];
  const categoryObject = {};
  r.forEach((response) => {
    const { categories, seriesData } = getSeriesCategories(model, response, cummulative);
    if (categories.length > 0) {
      let i = 0;
      categories.forEach((cat) => {
        if (!categoryObject[cat]) {
          categoryObject[cat] = {};
        }
        categoryObject[cat][seriesData[0].name] = seriesData[0].data[i][1];
        i++;
      });
    } else {
      seriesData[0].data.sort((a, b) => {
        return sortCompare(a[0], b[0]);
      });
      mainCategories.push(categories);
      mainSeries.push(seriesData[0]);
    }
  });
  if (mainCategories.length === 0) {
    const keys = Object.keys(categoryObject);
    sortWeeks(keys);
    keys.forEach((key, index) => {
      r.forEach((response, i) => {
        mainCategories[i] = mainCategories[i] || [];
        mainCategories[i][index + 1] = key;
        mainSeries[i] = mainSeries[i] || {
          data: [],
          name: response.chartTitle,
        };
        const d = [index + 1, categoryObject[key][response.chartTitle] || 0];
        mainSeries[i].data.push(d);
      });
    });
  }
  console.log(categoryObject);
  return { mainCategories, mainSeries };
};

const sortCompare = (a: string | number, b: string | number) => {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
};

export const sortWeeks = (weeks: string[]) => {
  weeks.sort((a, b) => {
    const w1 = a.split('-');
    const w2 = b.split('-');

    if (w1[1] === w2[1]) {
      return sortCompare(w1[0], w2[0]);
    } else {
      return sortCompare(w1[1], w2[1]);
    }
  });
};

export const getLineChartObject = (
  title: string,
  count: number,
  id: string,
  series: any[],
  yAxisTitle: string,
  xAxisTitle: string,
  categories: any[],
  scale: string,
  model: ChartModel,
  forms: FormInterface[],
  chartDataPoints?: ChartDataPoints,
  grouping?: string
) => {
  return {
    title: {
      text: `${title}`,
    },
    subtitle: {
      text: `Data points: ${count}`,
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
      minTickInterval: 1,
    },
    xAxis: {
      type: scale !== 'WEEK' ? 'datetime' : undefined,
      tickInterval: scale === 'MONTH' ? 24 * 3600 * 1000 * 30 : undefined,
      accessibility: {
        rangeDescription: xAxisTitle,
      },
      categories: categories.length > 0 ? categories : undefined,
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        // pointStart: 2010
      },
    },
    series: series,
    responseId: id,
    tooltip: {
      stickOnContact: true,
      useHTML: true,
      // @ts-ignore
      formatter: function () {
        const getX = (xValue) => {
          const date = new Date(xValue);
          if (scale === 'YEAR') {
            return `${date.getFullYear()}`;
          } else if (scale === 'MONTH') {
            return `${date.getFullYear()}-${pad(date.getMonth() + 1)}`;
          } else if (scale === 'DAY') {
            return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
          } else if (scale === 'WEEK') {
            return xValue;
          }
          return '';
        };
        const x = getX(this.x);
        const formId = this.series.userOptions.formId;
        const yAxis = this.series.userOptions.label || this.series.name;
        const chartData = chartDataPoints ? chartDataPoints[this.series.userOptions.formId] : null;
        const form = forms.find(f => f.ref === formId)?.name;
        const dataPointHtml = chartData ? chartData[yAxis] ?
          `<button class='btn btn-primary btn-sm' onClick="showChartModal(
            '${model.id}',
            '${x}',
            '${model.xaxis}',
            '${this.series.userOptions.label || this.series.name}',
            '${this.series.userOptions.name}',
            '${model.grouping ? `location${model.grouping}` : ''}',
            '${grouping || this.series.userOptions.grouping}',
            '${this.series.userOptions.formId}'
          )">View datapoints</button>` : `` : '';
        // @ts-ignore
        return (`
          ${form && model.showFormName ? `<b>Form ${form}</b><br/>` : ''}
          <b>${this.series.name} : <strong>${this.y}</strong> <br />${dataPointHtml}</b>
        `);
      },
    },
  };
};

export const getCombinedLocationLineChart = (
  model: ChartModel,
  chartResponse: ChartResponse[],
  forms: FormInterface[],
  cummulative?: boolean,
  locations?: Locations,
  chartDataPoints?: ChartDataPoints
) => {
  const chartGroups: { [key: string]: ChartResponse[] } = {};
  chartResponse.forEach((c) => {
    if (c.chartTitle) {
      chartGroups[c.chartTitle] = chartGroups[c.chartTitle] || [];
      const l = locations?.find((l) => `${l.key}` === `${c.grouping}`);
      if (l) {
        const cg = { ...c };
        cg.chartTitle = l.title;
        chartGroups[c.chartTitle].push(cg);
      }
    }
  });

  const titleKeys = Object.keys(chartGroups);
  const charts: any[] = [];
  titleKeys.forEach((key) => {
    const chartGroup = chartGroups[key];
    const { mainSeries, mainCategories } = getMainSeriesCategories(model, chartGroup, cummulative);
    mainSeries.forEach((series, index) => {
      series.label = key;
      series.grouping = chartGroup[index].grouping;
    });
    charts.push(
      getLineChartObject(
        `${key}`,
        chartGroup[0].count,
        chartGroup[0].id,
        mainSeries,
        '',
        ``,
        mainCategories[0],
        `${model.scale}`,
        model,
        forms,
        chartDataPoints,
      ),
    );
  });
  return charts;
};

export const addMissingNumericXAxisValues = (data) => {
  const length = data.series.length;
  const newSeries: JSONInterface[] = [];
  for (let i = 0; i < length; i++) {
    const current = data.series[i].x;
    const next = data.series[i + 1]?.x;
    if (next && Number(next) - Number(current) > 1) {
      newSeries.push({ ...data.series[i] });
      let newCur = Number(current) + 1;
      while (newCur < Number(next)) {
        newSeries.push({ x: newCur, y: 0, stack: null });
        newCur++;
      }
    } else {
      newSeries.push({ ...data.series[i] });
    }
  }
  data.series = newSeries;
  return data;
};

export const getRadarChart = (model: ChartModel, data, form: FormInterface) => {
  const formUtils = getFormUtils(form);
  const series: { type: string; name: string; data: number[]}[] = [];
  // const chars = 'abcdefghijklmnopqrstuvqxyz'.toUpperCase();
  const qnText = model.forms[form.ref].questions.map(q => {
    const qn = formUtils.getQuestion(q);
    if (qn) {
      return qn.text;
    }
    return q;
  });
  const tickInterval = 360 / qnText.length;
  data.forEach(dp => {
    const dt = model.forms[form.ref].questions.map(q => dp[q] || 0);
    series.push({
      type: 'line',
      name: dp['Name'],
      data: dt
    });
  });
  return {
    chart: {
      polar: true,
      height: 600
    },
    title: {
      text: model.name
    },
    pane: {
      startAngle: 0,
      endAngle: 360
    },
    xAxis: {
      tickInterval: tickInterval,
      min: 0,
      max: 360,
      labels: {
        formatter: function() {
          // return chars.charAt(this.value / tickInterval);
          return qnText[this.value / tickInterval];
        }
      }
    },
    yAxis: {
      min: 0
    },
    plotOptions: {
      series: {
        pointStart: 0,
        pointInterval: tickInterval
      },
    },
    series,
    tooltip: {
      // @ts-ignore
      formatter: function () {
        // @ts-ignore
        return (`
          <strong>${getLocalization('name')}:</strong> ${this.series.userOptions.name}<br/>
          <strong>${qnText[this.series.userOptions.index]} :</strong> ${this.y}
        `);
      },
      useHTML: true,
    },
  };
};

export const createCharts = (
  model: ChartModel,
  data: ChartResponse[],
  form: FormInterface,
  forms: FormInterface[],
  locations: Locations,
  chartDataPoints: ChartDataPoints
) => {
  let charts;
  switch (model.type) {
    case 'BAR_CHOICE':
    case 'BAR_NUMERIC':
      if (model.showMissingXAxisNumericValues === '1') {
        for (const d of data) {
          addMissingNumericXAxisValues(d);
        }
      }
      charts = getBarCharts(model, data, form, forms, locations, chartDataPoints);
      break;
    case 'PIE':
      charts = data.map((d) => {
        return getPieChartObject(model, d, locations);
      });
      break;
    case 'LINE_NUMERIC':
    case 'LINE_CHOICE':
      if (model.combine === 'FIELD') {
        charts = getCombinedLineChart(model, data, forms, undefined, locations, chartDataPoints);
      } else if (model.combine === 'LOCATION') {
        charts = getCombinedLocationLineChart(model, data, forms, undefined, locations, chartDataPoints);
      } else {
        charts = data.map((d) => d.series.length > 0 ?
          getLineChart(model, d, forms, undefined, locations, chartDataPoints) : null)
          .filter(c => c !== null);
      }
      break;
    case 'RADAR': {
      const formIds = Object.keys(model.forms);
      const f = forms.find(f => f.ref === formIds[0]);
      if (f) {
        charts = [getRadarChart(model, data, f)];
      }
      break;
    }
    default:
      charts = [];
  }
  return charts;
};

export const getQueryFilters = (model: ChartModel) => {
  const query = {};
  if (model.locations && model.locationLevels) {
    const locations = model.locations?.split(',');
    const levels = model.locationLevels?.split(',');
    locations.forEach((loc, index) => {
      const level = Number(levels[index]);
      const key = `location${level + 1}`;
      if (!query[key]) {
        query[key] = [];
      }
      query[key].push(loc);
    });
  }
  const filters: QueryFiltersInterface[] = [];
  if (model.users) {
    filters.push({ condition : 'IN', field : 'users', value : model.users, operator : '0', fieldOperator : '1' });
  }
  if (model.dateFilterBy) {
    if (model.fromDate) {
      filters.push({
        condition : 'GREATER_THAN_OR_EQUAL',
        field: model.dateFilterBy,
        value: model.fromDate,
        operator: '0',
        fieldOperator: '1'
      });
    }
    if (model.toDate) {
      filters.push({
        condition : 'GREATER_THAN_OR_EQUAL',
        field: model.dateFilterBy,
        value: model.toDate,
        operator: '0',
        fieldOperator: '1'
      });
    }
  }
  return { filters, query };
};
